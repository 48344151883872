import ApiService from '../api'
import { site } from '@foundation/constants/site'
import { helper, Helper } from '../helpers'
import { logger } from '../../../logging'
import { ICategory, ICategoryResponse } from '@features/category/query'
import { BaseArgs } from '@typesApp/common'

export interface CategoriesArgs extends BaseArgs {
  identifier?: string
  id?: string[]
}
const categoryURL =
  site.searchContextUrlInternal && site.searchContextUrlInternal.trim().endsWith('/')
    ? site.searchContextUrlInternal
    : `${site.searchContextUrlInternal}/`

const categoryService = new ApiService(categoryURL)
class CategoryApiService {
  private api: ApiService
  private helper: Helper
  constructor(api: ApiService, helper: Helper) {
    this.api = api
    this.helper = helper
  }

  public getCategories = (args: CategoriesArgs) => {
    const { generateQuery } = this.helper
    const query = generateQuery({
      identifier: args?.identifier,
      storeId: args?.storeId,
      langId: args?.langId,
      id: args?.id,
    })

    const url = `api/v2/categories?${query}`

    logger.info(`get categories data from ${url}`)

    return this.api.get<ICategoryResponse<ICategory>>(url)
  }
}

export const categoryApiService = new CategoryApiService(categoryService, helper)
